var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"context-menu"},[(_vm.isCannedResponseModalOpen && _vm.enabledOptions['cannedResponse'])?_c('woot-modal',{attrs:{"show":_vm.isCannedResponseModalOpen,"on-close":_vm.hideCannedResponseModal},on:{"update:show":function($event){_vm.isCannedResponseModalOpen=$event}}},[_c('add-canned-modal',{attrs:{"response-content":_vm.plainTextContent,"on-close":_vm.hideCannedResponseModal}})],1):_vm._e(),_vm._v(" "),(_vm.showTranslateModal)?_c('translate-modal',{attrs:{"content":_vm.messageContent,"content-attributes":_vm.contentAttributes},on:{"close":_vm.onCloseTranslateModal}}):_vm._e(),_vm._v(" "),(_vm.showDeleteModal)?_c('woot-delete-modal',{staticClass:"context-menu--delete-modal",attrs:{"show":_vm.showDeleteModal,"on-close":_vm.closeDeleteModal,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.TITLE'),"message":_vm.$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.MESSAGE'),"confirm-text":_vm.$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.DELETE'),"reject-text":_vm.$t('CONVERSATION.CONTEXT_MENU.DELETE_CONFIRMATION.CANCEL')},on:{"update:show":function($event){_vm.showDeleteModal=$event}}}):_vm._e(),_vm._v(" "),_c('woot-button',{attrs:{"icon":"more-vertical","color-scheme":"secondary","variant":"clear","size":"small"},on:{"click":_vm.handleOpen}}),_vm._v(" "),(_vm.isOpen && !_vm.isCannedResponseModalOpen)?_c('woot-context-menu',{attrs:{"x":_vm.contextMenuPosition.x,"y":_vm.contextMenuPosition.y},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"menu-container"},[(_vm.enabledOptions['replyTo'])?_c('menu-item',{attrs:{"option":{
          icon: 'arrow-reply',
          label: _vm.$t('CONVERSATION.CONTEXT_MENU.REPLY_TO')
        },"variant":"icon"},on:{"click":_vm.handleReplyTo}}):_vm._e(),_vm._v(" "),(_vm.enabledOptions['copy'])?_c('menu-item',{attrs:{"option":{
          icon: 'clipboard',
          label: _vm.$t('CONVERSATION.CONTEXT_MENU.COPY')
        },"variant":"icon"},on:{"click":_vm.handleCopy}}):_vm._e(),_vm._v(" "),(_vm.enabledOptions['copy'])?_c('menu-item',{attrs:{"option":{
          icon: 'translate',
          label: _vm.$t('CONVERSATION.CONTEXT_MENU.TRANSLATE')
        },"variant":"icon"},on:{"click":_vm.handleTranslate}}):_vm._e(),_vm._v(" "),_c('hr'),_vm._v(" "),_c('menu-item',{attrs:{"option":{
          icon: 'link',
          label: _vm.$t('CONVERSATION.CONTEXT_MENU.COPY_PERMALINK')
        },"variant":"icon"},on:{"click":_vm.copyLinkToMessage}}),_vm._v(" "),(_vm.enabledOptions['cannedResponse'])?_c('menu-item',{attrs:{"option":{
          icon: 'comment-add',
          label: _vm.$t('CONVERSATION.CONTEXT_MENU.CREATE_A_CANNED_RESPONSE')
        },"variant":"icon"},on:{"click":_vm.showCannedResponseModal}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }